:deep(.ant-modal-body) {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.radio-irem {
  padding-top: 0.2rem;
  padding-bottom: 0.1rem;
}

.radio-irem .radio-header {
  font-size: 0.18rem;
  font-weight: bold;
  color: #333333;
  margin-bottom: 0.2rem;
}

:deep(.ant-checkbox-wrapper),
:deep(.ant-radio-wrapper) {
  margin-right: 0.6rem;
  font-size: 0.16rem;
  color: #555555;
  margin-bottom: 0.1rem;
}

:deep(.ant-checkbox-wrapper):last-of-type,
:deep(.ant-radio-wrapper):last-of-type {
  margin-right: 0;
}

.dialog_content {
  padding-top: 0;
}

:deep(.ant-checkbox-input):focus + .ant-checkbox-inner,
:deep(.ant-checkbox):focus::after,
:deep(.ant-checkbox-wrapper):hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
:deep(.ant-checkbox-wrapper):hover .ant-checkbox-inner,
:deep(.ant-checkbox-wrapper):focus .ant-checkbox-inner,
:deep(.ant-checkbox-checked)::after,
:deep(.ant-checkbox):hover .ant-checkbox-inner {
  border-color: #f6a75c;
}

:deep(.ant-checkbox-checked) .ant-checkbox-inner {
  background-color: #f6a75c;
  border-color: #f6a75c;
  box-shadow: unset;
}

:deep(.ant-radio-wrapper):hover .ant-radio,
:deep(.ant-radio):hover .ant-radio-inner,
:deep(.ant-radio-input):focus + .ant-radio-inner {
  border-color: #f6a75c;
}