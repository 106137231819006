:deep(jmexpander) {
  line-height: 0.06rem;
  margin-top: 0.08rem;
  margin-left: 0.08rem;
}

.jsmind {
  height: 100%;
  position: relative;
}

.jsmind.full {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 10;
}

.jsmind .jsmind_container {
  flex: 1;
}

.jsmind :deep(.jsmind-inner) {
  cursor: grab;
  overflow: auto;
}

.jsmind :deep(.jsmind-inner)::-webkit-scrollbar {
  width: 0.06rem;
  height: 0.06rem;
  border-radius: 0.03rem;
  background-color: #e5e5e5;
}

.jsmind :deep(.jsmind-inner)::-webkit-scrollbar-thumb {
  border-radius: 0.03rem;
  background-color: #f6a75c;
}

.jsmind .toolbar {
  float: right;
  z-index: 2;
  position: absolute;
  right: 0.2rem;
  top: 0.2rem;
  z-index: 99;
  border: 0.01rem solid #dee0e3;
  background-color: #fff;
  border-radius: 0.05rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
}

.jsmind .toolbar .toolbar-list {
  padding: 0.06rem 0;
}

.jsmind .toolbar .toolbar-list .item {
  position: relative;
  width: 0.48rem;
  height: 0.36rem;
  cursor: pointer;
  font-size: 0.14rem;
  line-height: 0.36rem;
  text-align: center;
  margin-bottom: 0.12rem;
}

.jsmind .toolbar .toolbar-list .item svg {
  vertical-align: middle;
}

.jsmind .toolbar .toolbar-list .item.expand span {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.jsmind .toolbar .toolbar-list .item .percent {
  color: #1d1d1f;
  line-height: 1.45;
}

.jsmind .toolbar .toolbar-list .item:hover .scale-slide {
  opacity: 1;
  visibility: visible;
}

.jsmind .toolbar .toolbar-list .item.layout:hover .structure {
  opacity: 1;
  visibility: visible;
}

.jsmind .toolbar .toolbar-list .item .scale-slide {
  position: absolute;
  top: -0.07rem;
  right: 0.58rem;
  overflow: hidden;
  width: 2.44rem;
  height: 0.44rem;
  opacity: 1;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
  opacity: 0;
  visibility: hidden;
}

.jsmind .toolbar .toolbar-list .item .scale-slide:hover {
  opacity: 1;
  visibility: visible;
}

.jsmind .toolbar .toolbar-list .item .scale-slide ul {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 2.44rem;
  height: 0.44rem;
  padding: 0.04rem 0.08rem;
  border: 0.01rem solid #dee0e3;
  background-color: #fff;
  border-radius: 0.05rem;
  -webkit-box-shadow: 0 0 0.08rem 0.04rem #1f2329;
  box-shadow: 0 0 0.08rem 0.04rem #1f2329;
  list-style: none;
}

.jsmind .toolbar .toolbar-list .item .scale-slide ul .normal-item {
  display: flex;
  width: 0.36rem;
  height: 0.36rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.jsmind .toolbar .toolbar-list .item .scale-slide ul .slider {
  width: 100%;
}

.jsmind .toolbar .toolbar-list .item .scale-slide ul .slider :deep(.el-slider__runway) {
  height: 0.02rem;
  background-color: #bbbfc4;
}

.jsmind .toolbar .toolbar-list .item .scale-slide ul .slider :deep(.el-slider__runway) .el-slider__bar {
  background: none;
}

.jsmind .toolbar .toolbar-list .item .scale-slide ul .slider :deep(.el-slider__runway) .el-slider__button {
  border: 0.005rem solid #dee0e3;
}

.jsmind .toolbar .toolbar-list .item .scale-slide ul .style__zoom-scroll-bar___3n1YHZLZ {
  display: flex;
}

.jsmind .toolbar .toolbar-list .item .scale-slide ul .style__zoom-scroll-bar___3n1YHZLZ .style__zoom-bar___rp3v2Kdv {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 1.2rem;
  height: 0.36rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.jsmind .toolbar .toolbar-list .item .scale-slide ul .style__zoom-scroll-bar___3n1YHZLZ .style__zoom-bar___rp3v2Kdv .style__process-bar___2nCsCTk0 {
  position: relative;
  left: 50%;
  width: 1.04rem;
  height: 0.02rem;
  -ms-flex-item-align: center;
  align-self: center;
  margin-left: -0.52rem;
  background-color: #bbbfc4;
  border-radius: 0.02rem;
}

.jsmind .toolbar .toolbar-list .item .scale-slide ul .style__zoom-scroll-bar___3n1YHZLZ .style__zoom-bar___rp3v2Kdv .style__process-btn___azlkuc9i {
  position: absolute;
  width: 0.16rem;
  height: 0.16rem;
  border: 0.005rem solid #dee0e3;
  background-color: #fff;
  border-radius: 0.08rem;
  -webkit-box-shadow: 0 0.02rem 0.03rem 0 #000000;
  box-shadow: 0 0.02rem 0.03rem 0 #000000;
  cursor: pointer;
}

.jsmind .toolbar .toolbar-list .item .structure {
  position: absolute;
  top: 0;
  right: 0.58rem;
  width: 2.44rem;
  min-height: 1.2rem;
  max-height: calc(100vh - 2rem);
  cursor: default;
  opacity: 0;
  overflow-y: overlay;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
  visibility: hidden;
  z-index: 1000;
}

.jsmind .toolbar .toolbar-list .item .structure:hover {
  opacity: 1;
  visibility: visible;
}

.jsmind .toolbar .toolbar-list .item .structure .tab-content {
  padding: 0.24rem 0.24rem 0.1rem;
  border: 0.01rem solid #dee0e3;
  background-color: #fff;
  border-radius: 0.05rem;
  -webkit-box-shadow: 0 0 0.08rem 0.04rem #1f2329;
  box-shadow: 0 0 0.08rem 0.04rem #1f2329;
}

.jsmind .toolbar .toolbar-list .item .structure .tab-content .tab-label {
  margin-bottom: 0.12rem;
  color: #1f2329;
  font-size: 0.16rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
}

.jsmind .toolbar .toolbar-list .item .structure .tab-content .struct-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 0 0.2rem;
  list-style: none;
}

.jsmind .toolbar .toolbar-list .item .structure .tab-content .struct-list li {
  width: 0.32rem;
  height: 0.32rem;
  cursor: pointer;
}

.jsmind .toolbar .toolbar-list .reset {
  margin-bottom: 0;
}

.jsmind_layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* 隐藏滚动条 */
}

.jsmind_layout .jsmind_toolbar {
  width: 100%;
  padding: 0 0.1rem 0.1rem 0.1rem;
  height: auto;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #f8f9fa;
  box-shadow: 0 0 0.04rem #b8b8b8;
}

.jsmind_layout :deep(.el-button--medium),
.jsmind_layout :deep(.el-input--medium) {
  margin-top: 0.1rem;
}

.jsmind_layout #jsmind_container {
  flex: 1 1 auto;
}

.jsmind_layout :deep(.el-upload-list) {
  display: none !important;
}

.jsmind_layout .pad {
  margin-right: 0.1rem;
}

.jsmind_layout .pad-left {
  margin-left: 0.1rem;
}

.jsmind_layout :deep(jmnode) {
  max-width: unset;
  background-color: #ddd;
  box-shadow: unset;
  color: #666 !important;
  transition: 0s !important;
}

.jsmind_layout :deep(jmnode).selected {
  color: #666 !important;
  border: 0.01rem solid #777;
  transition: 1s;
}

.jsmind_layout :deep(jmnode) :deep(jmnode.selected) {
  background-color: #b9b9b9;
  color: #fff;
  box-shadow: 0.02rem 0.02rem 0.08rem #777;
}

.jsmind_layout :deep(jmnode) :deep(jmnode:hover) {
  box-shadow: 0.02rem 0.02rem 0.08rem #777;
}

.jsmind_layout :deep(jmnode.selected) {
  border: 0;
  box-shadow: 0.02rem 0.02rem 0.08rem #777;
}

.jsmind_layout :deep(jmnode:hover) {
  box-shadow: 0.02rem 0.02rem 0.08rem #777;
}