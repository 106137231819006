/deep/ .ant-tree-switcher {
  display: none !important;
}

/deep/ .ant-tree-child-tree {
  padding: 0 !important;
}

.tree_title {
  font-size: 0.16rem;
  color: #333333;
  line-height: 2;
  white-space: pre-wrap;
  display: block;
}

.tree_title > div {
  flex: 1;
}

/deep/ .ant-tree {
  font-size: 1em;
}

/deep/ .ant-tree > li {
  padding: 0 !important;
  font-size: 0;
}

/deep/ .ant-tree > li span {
  height: auto;
  padding: 0;
  border: 0 !important;
}

.model_main {
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 0.3rem;
  position: relative;
  transition: 0.5s;
  height: 100%;
}

.model_main::-webkit-scrollbar {
  width: 0.06rem;
  border-radius: 0.03rem;
  background-color: #e5e5e5;
}

.model_main::-webkit-scrollbar-thumb {
  width: 100%;
  border-radius: 0.03rem;
  background-color: #f6a75c;
}

.model_main .cardName {
  border-radius: 0.15rem;
  padding: 0 0.1rem;
  color: #fff;
  margin-right: 0.15rem;
  flex-shrink: 0;
}

.model_main .tree_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.model_main .tree_title > div {
  font-size: 1em;
  color: #333333;
  line-height: 1.667;
  padding: 0.05rem 0.1rem;
  font-weight: bold;
  flex: 1;
}

.model_main .tree_title .svg-icon {
  font-size: 0.18rem;
  color: #999;
  transition: 0.3s;
  cursor: pointer;
  margin-left: 0.2rem;
  flex-shrink: 0;
  outline: none;
}

.model_main .tree_title .svg-icon.active {
  color: #f6a75c;
}

.model_main .tree_title.type0 .tree_title_header {
  background-color: #dce8f3;
  display: flex;
  align-items: flex-start;
}

.model_main .tree_title.type1 .tree_title_header {
  padding: 0;
}

.model_main .tree_content {
  padding: 0.15rem 0.1rem 0.2rem;
  white-space: normal;
}

/deep/ .ant-tree-node-content-wrapper {
  width: 100%;
}

/deep/ .ant-tree-node-content-wrapper:hover,
/deep/ .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: transparent !important;
}

@media (max-width: 1024px) {
  .model_main {
    padding-right: 0.15rem;
  }

  .model_main .tree_title {
    font-size: 0.24rem;
  }

  .model_main .tree_title .svg-icon {
    display: none;
  }
}